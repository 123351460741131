import theme from '@manomano-internal/pineapple-ui/cjs/theme';
import SplashScreen from '@manomano-internal/pineapple-ui/cjs/SplashScreen';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React from 'react';

const Loading = ({ name }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <SplashScreen name={name} />
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Loading;
